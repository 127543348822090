<template>
  <div class="doctorMeets_container global_full_screen">
    <div class="doctorMeets_doctor_info">
      <div class="doctorMeets_doctor_info_box">
        <div class="doctor_info_block">
          <div class="doctor_info">
            <div>
              <span class="doctor_info_key">姓名：</span
              ><span class="doctor_info_value">{{ data.name }}</span>
            </div>
            <!-- <div @click="goToResumeInfo" class="doctor_info_link">
              简历信息完善
              <el-icon>
                <ArrowRightBold />
              </el-icon>
            </div> -->
          </div>
          <div class="doctor_info">
            <div>
              <span class="doctor_info_key">医院：</span
              ><span class="doctor_info_value">{{
                data.hospital || "暂无"
              }}</span>
            </div>

            <!-- <div
              class="doctor_info_link_doctor"
              @click="goToDoctorInfo"
              :class="!status ? '' : 'doctor_info_link_active'"
            >
              协议信息完善
              <el-icon>
                <ArrowRightBold />
              </el-icon>
            </div> -->
          </div>
        </div>
        <div @click="goToResumeInfo" class="doctor_info_link">
          简历信息完善
          <el-icon>
            <ArrowRightBold />
          </el-icon>
        </div>
      </div>
      <!-- <div class="doctor_info_id_card_error" v-if="data.id_card_audit === 1">
        <span>身份证照片有误，请点击个人信息重新上传</span>
      </div> -->
    </div>

    <div class="doctorMeets_meet_main global_meeting_padding">
      <div class="doctorMeets_meet_title">
        <img :src="require('@/assets/images/icon/list.png')" alt="" />
        <span
          >解读列表
          <!-- （{{ meetingCount }}） -->
        </span>
      </div>
      <div class="doctorMeets_meet_entry_name" v-if="meetingCount">
        项目名称: 肿瘤抗血管靶向治疗全国文献精读
      </div>
      <div class="doctorMeets_meet_list">
        <listScroll
          :url="$urls.admin.doctorMeetingList"
          :select="search"
          @receiveResponse="receiveResponse"
        >
          <template v-slot:listItem="{ citems }">
            <div class="doctorMeets_items">
              <div
                class="doctorMeets_items_row"
                @click="goToMeetDetail(citems)"
              >
                <div class="doctorMeets_item_information_block_detail">
                  <div class="doctorMeets_infomation_item meet_time">
                    <!-- <div class="meet_info_key">会议日期</div> -->
                    <div class="meet_info_value">
                      {{ citems.start_time.substr(5, 2) }}月{{
                        citems.start_time.substr(8, 2)
                      }}日
                      {{ getWeek(citems.start_time.substr(0, 10)) }}
                    </div>
                    <div class="meet_info_value">
                      {{ citems.start_time.substr(11, 5) }} -
                      {{ citems.end_time.substr(11, 5) }}
                    </div>
                  </div>
                  <div class="line"></div>
                  <div class="doctorMeets_infomation_item meet_theme">
                    <!-- <div class="meet_info_key">会议主题</div> -->
                    <div class="meet_info_value">
                      {{ citems.meeting_number }}
                    </div>
                    <div class="meet_info_value_ellipsis">
                      {{ citems.cour_title }}
                    </div>
                  </div>
                </div>
                <div
                  class="doctorMeets_item_information_block"
                  :class="
                    citems.state != 2 ? 'meet_state_wait' : 'meet_state_done'
                  "
                  style="flex: 20vw 0 0"
                >
                  <div class="doctorMeets_infomation_item">
                    <!-- {{$config.categary.meeting[citems.status]}} -->
                    <customButton
                      class="doctorMeets_item_button doctorMeets_infomation_item_state"
                    >
                      <div>
                        <span>
                          {{
                            citems.is_test
                              ? $config.categary.meeting[0]
                              : $tools.getMeetStatus(citems, 1, "待专家选题")
                          }}</span
                        >
                      </div>
                    </customButton>
                    <!-- {{
                      citems.is_test
                        ? $config.categary.meeting[0]
                        : $tools.getMeetStatus(citems, 1, "待专家选题")
                    }} -->
                    <!-- <el-icon>
                      <ArrowRightBold />
                    </el-icon> -->
                  </div>
                </div>
              </div>
              <div class="doctorMeets_item_button_group">
                <div style="display: flex; align-items: flex-end">
                  <div
                    v-if="citems.status == 0"
                    style="color: #ccc; font-size: 3.2vw"
                  >
                    您可在创建后任意时间录制
                  </div>
                </div>
                <!-- <customButton
                  class="doctorMeets_item_button"
                  v-if="citems.status == 0"
                  @click="goToPreveiw(citems)"
                >
                  <div>
                    <span>简历页预览</span>
                  </div>
                </customButton> -->
                <!-- <customButton
                  class="doctorMeets_item_button"
                  v-if="
                    citems.audit_status == $config.categary.audit.success &&
                    !citems.sign_name &&
                    !$tools.isPravate()
                  "
                  @click="goToAgreement(citems)"
                >
                  <div>
                    <span>协议签署</span>
                  </div>
                </customButton> -->
                <!--
                    <customButton
                  class="doctorMeets_item_button"
                  v-if="citems.status < 2"
                  @click="goToPreveiw(citems)"
                >
                  <div>
                    <span>简历页预览</span>
                  </div>
                </customButton>
                   <customButton
                  class="doctorMeets_item_button"
                  v-if="citems.status === 0 || citems.is_test == 1"
                  @click="goToMeetingInfo(citems)"
                >
                  <div>
                    <span>会议修改</span>
                  </div>
                </customButton>
                <customButton
                  class="doctorMeets_item_button"
                  v-if="citems.status < 2"
                  @click="goToMeeting(citems)"
                >
                  <div>
                    <span>进入会议</span>
                  </div>
                </customButton>
                <customButton
                  class="doctorMeets_item_button"
                  v-if="citems.status == 2"
                  @click="watchMeeting(citems)"
                >
                  <div>
                    <span>查看会议</span>
                  </div>
                </customButton>
                <customButton
                  class="doctorMeets_item_button"
                  v-if="
                    citems.status == 2 &&
                    citems.audit_status == $config.categary.audit.auditing
                  "
                  @click="returnReRcord(citems)"
                >
                  <div>
                    <span>退回重新录制</span>
                  </div>
                </customButton>
                <customButton
                  class="doctorMeets_item_button"
                  size="submit"
                  v-if="
                    citems.status == 2 &&
                    citems.audit_status == $config.categary.audit.auditing
                  "
                  @click="showSubmitPoup(citems)"
                >
                  <div>
                    <span>确认提交</span>
                  </div>
                </customButton>
                <customButton
                  class="doctorMeets_item_button"
                  v-if="
                    citems.audit_status == $config.categary.audit.fail &&
                    !$tools.isPravate()
                  "
                  @click="remindDoctorReRcord(citems)"
                >
                  <div>
                    <span>重新录制提醒</span>
                  </div>
                </customButton>
                <customButton
                  class="doctorMeets_item_button"
                  v-if="
                    citems.audit_status == $config.categary.audit.success &&
                    !citems.sign_name &&
                    !$tools.isPravate()
                  "
                  @click="remindDoctor(citems)"
                >
                  <div>
                    <span>协议签署提醒</span>
                  </div>
                </customButton> -->
              </div>
              <div
                class="doctorMeets_error_message"
                v-if="isShowMessage(citems) && citems.feed_content"
              >
                {{ getFeedContent(citems.feed_content)
                }}<span v-if="citems.id_card_audit == 1">
                  <span v-if="citems.id_card_audit_item"> ，</span
                  >{{ citems.id_card_audit_item }}，请点击下方协议信息核对修改
                </span>
              </div>
            </div>
          </template>
          <template v-slot:prompt="{ citems }">
            <div
              class="prompt"
              v-if="citems.audit_status == 20 && !data.id_card_audit"
            >
              <div @click="goToDoctorInfo(citems)">请点击此处完善协议信息</div>
            </div>
            <!-- 身份审核 -->
            <div class="prompt" v-if="data.id_card_audit === 1">
              <div @click="goToDoctorInfo(citems)">协议信息</div>
            </div>
          </template>
        </listScroll>
      </div>
      <div class="doctorMeets_button_group">
        <div class="doctorMeets_button_identity">
          <customButton
            class="doctorMeets_button_item"
            size="small"
            @click="goToGuide()"
          >
            <div class="doctorMeets_button_info">
              <span>操作指南下载</span>
            </div>
          </customButton>
          <customButton
            class="doctorMeets_button_item"
            size="small"
            @click="goToGuideVideo()"
          >
            <div class="doctorMeets_button_info">
              <span>操作视频查看</span>
            </div>
          </customButton>
        </div>
        <customButton
          v-if="!meetingCount"
          class="doctorMeets_button_item doctorMeets_button_create"
          size="small"
          @click="goToCreateMeeting"
          :class="verifyData(1) ? '' : 'customButton_dis'"
        >
          <div class="doctorMeets_button_info">
            <img
              :src="require('@/assets/images/icon/meet_create.png')"
              alt=""
            />
            <span>创建录制</span>
          </div>
        </customButton>
      </div>
    </div>
    <popup :cfg="popupCfg" v-if="isshowSubmitPoup">
      <div class="doctorMeets_tip_content">
        <div class="doctorMeets_tip_content_text">
          {{ getSubmitText() }}
        </div>
        <div class="doctorMeets_add_page">
          <customButton class="doctorMeets_help_button" @click="doSubmit()">
            确认提交
          </customButton>
          <customButton
            class="doctorMeets_help_button"
            @click="hideSubmitPoup()"
          >
            取消
          </customButton>
        </div>
      </div>
    </popup>
    <popup :cfg="popupCfg" v-if="isPoup">
      <div class="doctorMeets_tip_content">
        <div class="doctorMeets_tip_content_text">仅邀请副高及以上专家参与</div>
        <div class="doctorMeets_add_page">
          <customButton
            class="doctorMeets_help_button"
            @click="doDoctorSubmit()"
          >
            确认
          </customButton>
          <!-- <customButton
            class="doctorMeets_help_button"
            @click="hideSubmitPoup()"
          >
            取消
          </customButton> -->
        </div>
      </div>
    </popup>
    <popup :cfg="popupCfg" v-if="isResumeInfoPoup">
      <div class="doctorMeets_tip_content">
        <div class="doctorMeets_tip_content_text">请先确认您的简历信息</div>
        <div class="doctorMeets_add_page">
          <customButton class="doctorMeets_help_button" @click="doResumeInfo()">
            确认
          </customButton>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import { ElIcon } from "element-plus";
import { ArrowRightBold } from "@element-plus/icons";
import customButton from "@/components/unit/customButton";
import listScroll from "@/components/unit/listScroll";
import popup from "@/components/unit/popup";

export default {
  name: "doctorMeets",
  components: {
    customButton,
    listScroll,
    ArrowRightBold,
    ElIcon,
    popup,
  },
  data() {
    return {
      submit: {
        32: "请确认是否已查看会议，并满足项目要求（如音质清晰，内容完整等）",
        35: "请确认是否已查看会议，并满足项目要求（如音质清晰，录制内容完整等）",
        36: "请确认是否已查看会议，并满足项目要求（如音质清晰，录制内容完整等）",
        default:
          "请确认是否已查看会议，并满足项目要求（如音质清晰，包含病例内容等）",
      },
      popupCfg: {
        title: "",
        content: "",
        button: [],
      },
      search: {
        id: 0,
      },
      submitAuditTips: {
        25: "退回成功,请联系专家重新录制",
        15: "提交成功",
      },
      submitData: {},
      meetingCount: 0,
      isshowSubmitPoup: false,
      isResumeInfoPoup: false,
      data: {},
      loading: false,
      isPoup: false,
      isResumeInfo: 0, //是否确认简历信息
    };
  },
  created() {
    let adminInfo = this.$store.state.common.adminInfo;
    this.search.id = process.env.VUE_APP_doctorId || adminInfo.doctor_id;
    this.search.project_id = this.$tools.getStorage(
      "projectId",
      sessionStorage
    );
    console.log("this.search.project_id  :>> ", this.search.project_id);
    this.loadData();
    this.isResumeInfo =
      this.$tools.getStorage("isResumeInfo", localStorage) || 0;
    document.getElementsByTagName("title")[0].innerHTML = "解读状态";
  },
  methods: {
    getWeek(citems) {
      let week = {
        1: "周一",
        2: "周二",
        3: "周三",
        4: "周四",
        5: "周五",
        6: "周六",
        7: "周日",
      };
      return week[new Date(citems).getDay()];
    },
    getSubmitText() {
      let projectId = this.$tools.getStorage("projectId", sessionStorage);
      return this.submit[projectId] || this.submit.default;
    },
    loadData() {
      let url = this.$tools.getURL(this.$urls.admin.doctorInfo, this.search);
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {});
    },
    receiveResponse(response) {
      console.log(response);
      this.meetingCount = response.count;
      if (!this.meetingCount) {
        sessionStorage.removeItem("TestMeetInfo");
      }
    },
    getFeedContent(content) {
      return this.$tools.isPravate()
        ? content.replace("协议待签署", "视频待制作")
        : content;
    },
    goToMeetDetail(citems) {
      this.$router.push({
        path: "/meetDetail",
        query: { ...this.search, id: citems.id },
      });
    },
    goToPreveiw(item) {
      if (item.custom_courseware && !item.courseware_id) {
        this.$tips.error({ text: "待专家选题，简历暂时无法预览" });
        return;
      }
      this.$router.push({
        path: "/doctorPreview",
        query: { id: item.id },
      });
    },
    goToDoctorInfo(ctimes) {
      setTimeout(() => {
        if (!this.meetingCount) {
          this.$tips.error({ text: "需录制审核通过后才可完善信息" });
          return;
        }
        if (ctimes.audit_status != 20) {
          this.$tips.error({ text: "需录制审核通过后才可完善信息" });
          return;
        }
        this.$tools.setStorage("type", "1", sessionStorage); //进入签署协议的方式 列表进1  详情进2 //公众号进
        this.$router.push({
          path: "/doctorInfo",
          query: {
            ...this.search,
            meetId: ctimes.id,
          },
        });
      }, 300);
    },
    goToResumeInfo() {
      this.isPoup = !this.isPoup;
      // this.$router.push({
      //   path: "/resumeInfo",
      //   query: this.search,
      // });
    },
    doDoctorSubmit() {
      this.isPoup = !this.isPoup;
      this.$router.push({
        path: "/resumeInfo",
        query: this.search,
      });
    },

    verifyData(type) {
      let verifyList = [];
      if (type == 1) {
        verifyList = [
          "name",
          "hospital",
          "phone",
          "profile",
          "department",
          "province",
          "city",
          "resume",
          // "job",//非必填
          // "resume_job",
          "resume_academic",
        ];
      } else {
        verifyList = [
          "id_card_number",
          "bank",
          "bank_number",
          "id_card_front",
          "id_card_back",
        ];
      }
      for (let index in verifyList) {
        let key = verifyList[index];
        if (!this.data[key]) {
          return false;
        }
      }
      return true;
    },
    isShowMessage(data) {
      return [10, 20, 30].includes(data.audit_status);
      // return [10, 20, 30].includes(data.audit_status) && data.status == 2;
    },
    goToAgreement(data) {
      this.$router.push({
        path: `/agreement/${data.id}/${this.search.id}`,
      });
    },
    remindDoctor(data) {
      if (this.loading) return;
      this.loading = true;
      let url = this.$tools.getURL(this.$urls.admin.meetSign, { id: data.id });
      this.$axios
        .get(url)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$tips.success({ text: "提醒成功" });
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    remindDoctorReRcord(data) {
      // todo.....
      if (this.loading) return;
      this.loading = true;
      let url = this.$tools.getURL(this.$urls.admin.meetError, { id: data.id });
      this.$axios
        .get(url)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$tips.success({ text: "提醒成功" });
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    returnReRcord(data) {
      let tempData = {
        id: data.id,
        status: this.$config.categary.audit.rerecord,
      };
      this.doAuditStatus(tempData, data);
    },
    showSubmitPoup(data) {
      this.submitData = data;
      this.isshowSubmitPoup = true;
    },
    hideSubmitPoup() {
      this.submitData = {};
      this.isshowSubmitPoup = false;
    },
    doSubmit() {
      let tempData = {
        id: this.submitData.id,
        status: this.$config.categary.audit.submitAudit,
      };
      this.isshowSubmitPoup = false;
      this.doAuditStatus(tempData, this.submitData);
    },
    doAuditStatus(data, item) {
      // console.log(data,item)
      // return
      if (this.loading) return;
      this.loading = true;
      let url = this.$urls.admin.auditStatus;
      // this.$tips.success({text:`${this.submitAuditTips[data.status]}`})
      this.$axios
        .patch(url, data)
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          item.audit_status = data.status;
          if (data.status === 25) {
            item.status = 1;
          }
          this.loadData();
          this.$tips.success({ text: `${this.submitAuditTips[data.status]}` });
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToMeetingInfo(item) {
      this.$router.push({
        path: "/createMeeting",
        query: {
          id: this.search.id,
          meetId: item.id,
        },
      });
    },
    goToMeeting(data) {
      if (!data.courseware_id) {
        this.$tips.error({ text: "待专家选题" });
        return;
      }
      let url = data.meeting_url.split("#")[1];
      this.$router.push({
        path: url,
        query: {
          role: this.$config.categary.role.guest,
        },
      });
    },
    watchMeeting(data) {
      if (!data.courseware_id) {
        this.$tips.error({ text: "待专家选题" });
        return;
      }
      let url = data.meeting_url.split("#")[1];
      this.$router.push({
        path: url,
        query: {
          role: this.$config.categary.role.doctor,
          admin: 1,
          watch: 1,
          openid: data.openid,
        },
      });
    },
    removeCurDataTemporaryStorage() {
      this.$tools.removeStorage("createMeetingData", sessionStorage);
      this.$tools.removeStorage("coursewareInfo", sessionStorage);
    },
    goToCreateMeeting() {
      setTimeout(() => {
        if (!this.verifyData(1)) {
          this.$tips.error({ text: "请先完善专家简历信息" });
          return;
        }
        this.removeCurDataTemporaryStorage();
        if (!this.isResumeInfo) {
          this.$tools.setStorage("isResumeInfo", 1, localStorage);
          this.isResumeInfoPoup = true;
        } else {
          this.$router.push({
            path: "/createMeeting",
            query: this.search,
          });
        }
      }, 300);
    },
    doResumeInfo() {
      this.$router.push({
        path: "/resumeInfo",
        query: this.search,
      });
    },
    goToGuideVideo() {
      window.open(
        "https://pbx.kclass.imedunion.com/pbx/files/files/操作视频0919.mp4"
      );
    },
    goToGuide() {
      window.open(
        "https://pbx.kclass.imedunion.com/pbx/files/files/专家操作指南-肿瘤抗血管靶向治疗全国文献精读项目_2023-9-20.pdf"
      );
    },
  },
};
</script>
<style>
.doctorMeets_container {
  background: #ececec;
  display: flex;
  flex-direction: column;
}
.doctorMeets_error_message {
  color: red;
  margin-top: 4vw;
}
.doctorMeets_doctor_info {
  flex: 22.93vw 0 0;
  background: #fff;
  margin-bottom: 2vw;
  padding: 6vw 4vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.doctor_info_key {
  color: #0000c9;
  font-size: 4vw;
  font-weight: 400;
}
.doctor_info_value {
  font-size: 4vw;
  font-weight: 400;
}
.doctor_info_block {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctor_info_link {
  font-size: 4vw;
  flex: 20vw 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0000c9;
  white-space: nowrap;
}
.doctor_info_link_doctor {
  color: #9b9b9b;
  font-size: 4vw;
  flex: 20vw 0 0;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.doctorMeets_container .el-icon {
  /* color: #777777; */
  font-size: 4vw;
}
.doctorMeets_meet_main {
  padding: 0 4vw 4vw 4vw;
  flex: 1 0 0;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.doctorMeets_meet_title {
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* color: #0000c9; */
  font-weight: 600;
  padding: 4.8vw 0;
  flex: 13.6vw 0 0;
  border-bottom: #ccc 1px solid;
}
.doctorMeets_meet_title img {
  height: 5.1vw;
  width: auto;
  margin-right: 1vw;
  color: #0000c9;
}
.doctorMeets_meet_entry_name {
  color: #9b9b9b;
  font-size: 4vw;
  padding: 4vw 0;
}
.doctorMeets_meet_list {
  flex: 1 0 0;
}
.doctorMeets_items {
  padding: 3vw;
  border: #ccc 1px solid;
  border-radius: 2vw;
  margin-top: 2vw;
}
.doctorMeets_items_row {
  display: flex;
  /* flex: 1 0 0; */
  /* padding-bottom: 4vw; */
}
.doctorMeets_item_information_block_detail {
  display: flex;
  justify-content: space-between;
  flex: 1 0 0;
}
.doctorMeets_item_information_block {
  display: flex;
  justify-content: space-between;
}
.doctorMeets_item_state {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.doctorMeets_item_button_group {
  flex: 7.6vw 0 0;
  display: flex;
  justify-content: space-between;
}
.doctorMeets_item_button {
  margin-left: 5vw;
  width: auto;
  font-size: 3.6vw;
  padding: 0 4vw;
}
.doctorMeets_button_group {
  /* flex: 20vw 0 0; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctorMeets_button_item {
  height: 9.4vw;
  width: 100%;
}
.doctorMeets_button_info {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.doctorMeets_button_info img {
  height: 5.1vw;
  width: auto;
  margin-right: 1vw;
}
.meet_info_key {
  color: #9b9b9b;
  font-size: 4vw;
  margin-right: 4vw;
  font-weight: 400;
  white-space: nowrap;
}
.meet_info_value {
  flex: 1 0 0;
  font-size: 4vw;
  font-weight: 400;
  /* word-break: break-all; */
}
.meet_state_wait {
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0000c9;
}
.meet_state_done {
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b9b9b;
}
.doctorMeets_infomation_item {
  display: flex;
  flex-direction: column;
  padding: 2vw 0;
}

.doctorMeets_tip_content {
  /* display: flex; */
  /* flex-direction: column; */
  padding: 4vw 0;
}

.doctorMeets_tip_content_text {
  display: flex;
  align-items: center;
  height: 16vw;
  padding: 0 5vw;
}

.doctorMeets_add_page {
  display: flex;
  justify-content: center;
}
.doctorMeets_add_page .doctorMeets_help_button {
  flex: 30% 0 0;
  padding: 2vw 0;
}
.doctorMeets_add_page .doctorMeets_help_button:nth-child(2) {
  margin-left: 5vw;
}
.doctor_info_id_card_error {
  color: #f00;
  margin-top: 3vw;
}
.doctorMeets_doctor_info_box {
  display: flex;
  height: 15vw;
}
.line {
  border-right: 1px solid #ccc;
  margin: 0 1.5vw;
}
.meet_info_value_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.prompt {
  margin-top: 4vw;
  color: #fff;
  text-align: center;

  display: flex;
  justify-content: center;
}
.prompt div {
  width: 80%;
  background: #0000c9;
  padding: 2vw 4vw;
}
.doctor_info_link_active {
  color: #0000c9;
}
.customButton_dis {
  background: #7f7f7f !important;
  border-radius: 2vw;
}
.doctor_info {
  display: flex;
  justify-content: space-between;
}
.doctorMeets_infomation_item_state {
  padding: 0 1vw;
  margin-left: 0;
  width: 20vw;
}
.doctorMeets_container .meet_time {
  flex: 0 0 27vw;
}
.doctorMeets_container .meet_theme {
  flex: 1 0 0;
}
.doctorMeets_button_identity {
  display: flex;
  justify-content: space-between;
  height: 9.4vw;
}
.doctorMeets_button_identity .doctorMeets_button_item {
  width: 45%;
}
.doctorMeets_button_create {
  margin-top: 4vw;
}
</style>
